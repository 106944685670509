<app-lp-template size="lg">
    <div class="px-2 pb-2 pt-2 input-block">
        <h2 class="text-center mt-0">Registrazione tecnico</h2>
        <form clrForm ngNativeValidate clrLayout="vertical" (submit)="register()">
            <h3>Dati Tecnico</h3>

            <div class="clr-row">
                <div class="clr-col">
                    <clr-input-container>
                        <label class="clr-required-mark">Nome e cognome</label>
                        <input clrInput type="text" [(ngModel)]="model.nome" name="nome" required/>
                    </clr-input-container>
                </div>
            </div>
            <div class="clr-row">
                
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Luogo di nascita</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_luogoNascita" name="tecnico_luogoNascita" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Provincia di nascita</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_provinciaNascita" name="tecnico_provinciaNascita" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-date-container>
                        <label class="clr-required-mark">Data di nascita</label>
                        <input type="date" autocomplete="off" clrDate name="tecnico_dataNascita" [(ngModel)]="model.tecnico_dataNascita" placeholder="GG/MM/AAAA" maxlength="10" mask="d0/M0/0000" required />
                    </clr-date-container>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Codice fiscale</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_cf" name="tecnico_cf" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Partita IVA</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_piva" name="tecnico_piva" required/>
                    </clr-input-container>
                </div>
            </div>
            <clr-input-container>
                <label class="clr-required-mark">Indirizzo email</label>
                <input clrInput type="text" [(ngModel)]="model.email" name="email" required/>
            </clr-input-container>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Codice univoco</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_codiceUnivoco" name="tecnico_codiceUnivoco" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">PEC</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_pec" name="tecnico_pec" required/>
                    </clr-input-container>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-select-container>
                        <label class="clr-required-mark">Iscritto all'albo</label>
                        <select clrSelect name="options" [(ngModel)]="model.tecnico_albo">
                            <option value="{{albo.GEOMETRA}}">Geometra</option>
                            <option value="{{albo.ARCHITETTO}}">Architetto</option>
                            <option value="{{albo.INGEGNERE}}">Ingegnere</option>
                            <option value="{{albo.PERITO_EDILE}}">Perito edile</option>
                            </select>
                    </clr-select-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Provincia albo</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_provincia_albo" name="tecnico_provincia_albo" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Numero iscrizione albo</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_numero_iscrizione_albo" name="tecnico_numero_iscrizione_albo" required/>
                    </clr-input-container>
                </div>
            </div>
            

            <h3 class="mt-3">Dati sede</h3>
            <clr-input-container>
                <label class="clr-required-mark">Indirizzo</label>
                <input clrInput type="text" [(ngModel)]="model.tecnico_sede_indirizzo" name="tecnico_sede_indirizzo" required/>
            </clr-input-container>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">CAP</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_sede_cap" name="tecnico_sede_cap" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Comune</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_sede_localita" name="tecnico_sede_comune" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Provincia</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_sede_provincia" name="tecnico_sede_provincia" required/>
                    </clr-input-container>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Telefono</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_sede_telefono" name="tecnico_sede_telefono" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Cellulare</label>
                        <input clrInput type="text" [(ngModel)]="model.tecnico_sede_cellulare" name="tecnico_sede_cellulare" required/>
                    </clr-input-container>
                </div>
            </div>

            <h3 class="mt-3">Dati accesso al portale</h3>
            <clr-password-container>
                <label class="clr-required-mark">Password</label>
                <input clrPassword placeholder="" [(ngModel)]="model.password" name="password" required/>
            </clr-password-container>
            <clr-password-container>
                <label class="clr-required-mark">Conferma password</label>
                <input clrPassword placeholder="" [(ngModel)]="confermaPassword" name="confermaPassword" required/>
            </clr-password-container>

            <div class="clr-form-control">
                <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                        <input type="checkbox" clrCheckbox required id="condizioni" name="condizioni"/>
                        <label for="condizioni">Accetto le <a target="_blank" href="../../../assets/CondizioniUso.pdf">condizioni d'uso</a></label>
                    </div>
                </div>
            </div>
            
            <button class="btn btn-primary btn-block mt-2" type="submit">
                <app-loading *ngIf="loading"></app-loading>
                <span *ngIf="!loading">INVIA</span>
            </button>
        </form>
    </div>
</app-lp-template>