export enum TipiDocumento {
    APE_AGENZIA = 1, // APE
    PR = 2, // Documento identità proprietario
    PRIV = 3, // Delega privacy
    SCCAT_AGENZIA = 4, // Scheda catastale caricata da agenzia
    VICAT_AGENZIA = 5, // Visura catastale caritada da agenzia
    ATT = 6, // Atto
    APE_TECNICO = 7, // APE
    SCCAT_TECNICO = 8, // Scheda catastale
    VICAT_TECNICO = 9, // Visura catastale
    AGGCAT = 10, // Aggiornamento catastale
    VISIP_TECNICO = 11, // Visura ipotecaria
    CONDED_TECNICO = 12, // Condono edilizio
    NPRATC = 13, // Nuova pratica comunale
    RELTEC_TECNICO = 14, // Relazione tecnica
    PLANQU = 15, // Planimetria quotata
    ATTSUC_AGENZIA = 16, // Atto successione
    CF = 17, // Codice fiscale
    ALTRO_AGENZIA = 18, // Altro
    FAT = 19, // Fattura
    VPRATE_TECNICO = 20, // Pratica edilizia precedente
    NPRATE_TECNICO = 21, // Nuova pratica edilizia
    NAGGCAT_TECNICO = 22, // Nuovo aggiornamento catastale
    RIACCA = 23, // Richiesta accesso atti
    PRCOM_AGENZIA = 24, // Pratica comunale
    PREL = 25, // Preliminare
    LTINC = 26, // Lettera di incarico
    REGCON_AGENZIA = 27, // Regolamento condominiale
    CONSPE = 28, // Consuntivo spese condominiali
    CERMAT = 29, // Certificato di matrimonio
    CONIMP = 30, // Conformità impianti
    ATNOT_AGENZIA = 31, // Atti notarili
    NTRAS_AGENZIA = 32, // Nota di trascrizione
    CONVED_AGENZIA = 33, // Convenzione edilizia
    VISIP_AGENZIA = 34, // Visure ipotecarie
    AALELO_AGENZIA = 35, // Agibilità, Abitabilità, Licenza edilizia
    CONDED_AGENZIA = 36, // Condono edilizio
    CONCED_AGENZIA = 37, // Concessioni edilizie
    PERCOS_AGENZIA = 38, // Permesso di costruire
    AUTED_AGENZIA = 39, // Autorizzazioni edilizie
    FINLAV = 40, // Fine lavori
    ALTRO_TECNICO = 41, // Altro
    PACQ = 42, // Proposta d'acquisto
    PROVV = 43, // Provvigioni
    RICASS = 44, // Ricevute e copie assegni
    COMACC = 45, // Comunicazione avvenuta accettazione
    CPREL = 46, // Contratto preliminare
    RRPREL = 47, // Ricevuta registrazione preliminare
    ALTRO = 48, // Altro
    CERRES = 49, // Certificato di residenza
    STAFAM = 50, // Certificato di stato di famiglia
    ACQALT = 51, // Altri documenti acquirente
    LIBAMM = 52, // Liberatoria amministratore
    ROGALT = 53, // Altri documenti utili al rogito
    DICAST = 54, // Dichiarazioni di avventua stipula
    ALTNOT = 55, // Altro
    IMGS = 56, // Immagini immobile
    PLANIM = 57, // Planimetria
    PLANQU2 = 58, // Planimetria quotata
    CAGIB = 59, // Agibilità
    CLICED = 60, // Licenze
    CCONED = 61, // Concessioni edilizie
    CCONDE = 62, // Condoni edilizi
    CPDC = 63, // PDC
    CAUTO = 64, // Autorizzazioni
    CDSC = 65, // DIA/SCIA/CILA
    CFINEL = 66, // Fine lavori
    CALTR = 67, // Altro
    ACQDOC = 68, // Documento identità acquirente
    ACQCF = 69, // Codice fiscale acquirente
    ACQPRI = 70, // Privacy acquirente
    CIVLUC = 71, // Carta d'identità luce
    CIVGAS = 72, // Carta d'identità gas
    CFVLUC = 73, // CF luce
    CFVGAS = 74, // CF gas
    UFTLUC = 75, // Ultima fattura luce
    UFTGAS = 76, // Ultima fattura gas
    COPBRC = 77, // Immagine copertina brochure
    RTI = 78, // Relazione Tecnica Integrata,
    RTI_PLAN = 79, // Planimetria RTI
    PREV_TECNICO = 80
}

export const TipiLabel: Record<TipiDocumento, string> = {
    [TipiDocumento.APE_AGENZIA]: "APE caricato da agenzia",
    [TipiDocumento.PR]: "Documento d'identità proprietario",
    [TipiDocumento.PRIV]: "Delega privacy",
    [TipiDocumento.SCCAT_AGENZIA]: "Planimetria catastale caricata da agenzia",
    [TipiDocumento.VICAT_AGENZIA]: "Visura catastale caricata da agenzia",
    [TipiDocumento.ATT]: "Atto",
    [TipiDocumento.APE_TECNICO]: "APE caricato da tecnico",
    [TipiDocumento.SCCAT_TECNICO]: "Planimetria catastale caricata da tecnico",
    [TipiDocumento.VICAT_TECNICO]: "Visura catastale caricata da tecnico",
    [TipiDocumento.AGGCAT]: "Aggiornamento catastale",
    [TipiDocumento.VISIP_TECNICO]: "Visura ipotecaria caricata da tecnico",
    [TipiDocumento.CONDED_TECNICO]: "Condono edilizio caricato da tecnico",
    [TipiDocumento.NPRATC]: "Nuova pratica comunale",
    [TipiDocumento.RELTEC_TECNICO]: "Relazione Tecnica",
    [TipiDocumento.PLANQU]: "Planimetria quotata",
    [TipiDocumento.ATTSUC_AGENZIA]: "Atto di successione",
    [TipiDocumento.CF]: "Codice fiscale",
    [TipiDocumento.ALTRO_AGENZIA]: "Altro caricato da agenzia",
    [TipiDocumento.FAT]: "Fattura",
    [TipiDocumento.VPRATE_TECNICO]: "Pratica edilizia precedente",
    [TipiDocumento.NPRATE_TECNICO]: "Nuova pratica edilizia",
    [TipiDocumento.NAGGCAT_TECNICO]: "Nuovo aggiornamento catastale",
    [TipiDocumento.RIACCA]: "Richiesta accesso atti",
    [TipiDocumento.PRCOM_AGENZIA]: "Pratica comunale",
    [TipiDocumento.PREL]: "Preliminare",
    [TipiDocumento.LTINC]: "Lettera d'incarico",
    [TipiDocumento.REGCON_AGENZIA]: "Regolamento condominiale",
    [TipiDocumento.CONSPE]: "Consuntivo spese condominiali",
    [TipiDocumento.CERMAT]: "Certificato di matrimonio",
    [TipiDocumento.CONIMP]: "Confomità impianti",
    [TipiDocumento.ATNOT_AGENZIA]: "Atti notarili",
    [TipiDocumento.NTRAS_AGENZIA]: "Nota di trascrizione",
    [TipiDocumento.CONVED_AGENZIA]: "Convenzione edilizia",
    [TipiDocumento.VISIP_AGENZIA]: "Visura ipotecaria caricata da agenzia",
    [TipiDocumento.AALELO_AGENZIA]: "Agibilità, Abitabilità, Licenza edilizia",
    [TipiDocumento.CONDED_AGENZIA]: "Condono edilizio caricato da agenzia",
    [TipiDocumento.CONCED_AGENZIA]: "Concessioni edilizie",
    [TipiDocumento.PERCOS_AGENZIA]: "Permesso di costruire",
    [TipiDocumento.AUTED_AGENZIA]: "Autorizzazioni edilizie",
    [TipiDocumento.FINLAV]: "Fine lavori",
    [TipiDocumento.ALTRO_TECNICO]: "Altro caricato da tecnico",
    [TipiDocumento.PACQ]: "Proposta d'acquisto",
    [TipiDocumento.PROVV]: "Provvigioni",
    [TipiDocumento.RICASS]: "Ricevute e copie assegni",
    [TipiDocumento.COMACC]: "Comunicazione avvenuta accettazione",
    [TipiDocumento.CPREL]: "Contratto preliminare",
    [TipiDocumento.RRPREL]: "Ricevuta registrazione preliminare",
    [TipiDocumento.ALTRO]: "Altro",
    [TipiDocumento.CERRES]: "Certificato di residenza",
    [TipiDocumento.STAFAM]: "Certificato di stato di famiglia",
    [TipiDocumento.ACQALT]: "Altri documenti acquirente",
    [TipiDocumento.LIBAMM]: "Liberatoria amministratore",
    [TipiDocumento.ROGALT]: "Altri documenti utili al rogito",
    [TipiDocumento.DICAST]: "Dichiarazioni di avvenuta stipula",
    [TipiDocumento.ALTNOT]: "Altro",
    [TipiDocumento.IMGS]: "Immagini immobile",
    [TipiDocumento.PLANIM]: "Planimetria",
    [TipiDocumento.PLANQU2]: "Planimetria quotata",
    [TipiDocumento.CAGIB]: "Agibilità",
    [TipiDocumento.CLICED]: "Licenze",
    [TipiDocumento.CCONED]: "Concessioni edilizie",
    [TipiDocumento.CCONDE]: "Condoni edilizi",
    [TipiDocumento.CPDC]: "PDC",
    [TipiDocumento.CAUTO]: "Autorizzazioni",
    [TipiDocumento.CDSC]: "DIA/SCIA/CILA",
    [TipiDocumento.CFINEL]: "Fine lavori",
    [TipiDocumento.CALTR]: "Altro",
    [TipiDocumento.ACQDOC]: "Documento di identità acquirente",
    [TipiDocumento.ACQCF]: "Codice fiscale acquirente",
    [TipiDocumento.ACQPRI]: "Privacy acquirente",
    [TipiDocumento.CIVLUC]: "Carta d'identità voltura luce",
    [TipiDocumento.CIVGAS]: "Carta d'identità voltura gas",
    [TipiDocumento.CFVLUC]: "CF voltura luce",
    [TipiDocumento.CFVGAS]: "CF voltura gas",
    [TipiDocumento.UFTLUC]: "Ultima fattura voltura luce",
    [TipiDocumento.UFTGAS]: "Ultima fattura voltura gas",
    [TipiDocumento.COPBRC]: "Immagine copertina brochure",
    [TipiDocumento.RTI]: "Relazione tecnica integrata",
    [TipiDocumento.RTI_PLAN]: "Planimetria per relazione tecnica integrata",
    [TipiDocumento.PREV_TECNICO]: "Preventivo"
  };