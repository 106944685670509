<div class="page-container">
    <div class="px-2 pb-2 pt-2 input-block">
        <h2 class="text-center">{{ !edit ? "Nuovo tecnico" : "Anagrafica tecnico" }}</h2>
        <form clrForm ngNativeValidate clrLayout="vertical" (submit)="save()">
            <h3>Dati Tecnico</h3>

            <clr-select-container>
                <label class="clr-required-mark">Ruolo</label>
                <select clrSelect name="ruolo" [(ngModel)]="ruolo" [disabled]="!auth.isAdmin">
                    <option value="interno">Tecnico interno</option>
                    <option value="esterno">Tecnico esterno</option>
                    <option value="spa">Tecnico esterno (SPA)</option>
                </select>
            </clr-select-container>
            <div class="clr-row">
                <div class="clr-col">
                    <clr-input-container>
                        <label class="clr-required-mark">Nome e cognome</label>
                        <input clrInput type="text" [(ngModel)]="model.nome" name="nome" required/>
                    </clr-input-container>
                </div>
            </div>
            <ng-container *ngIf="ruolo != 'interno'">
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Luogo di nascita</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_luogoNascita" name="tecnico_luogoNascita" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Provincia di nascita</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_provinciaNascita" name="tecnico_provinciaNascita" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-date-container>
                            <label class="clr-required-mark">Data di nascita</label>
                            <input type="date" autocomplete="off" clrDate name="tecnico_dataNascita" [(ngModel)]="model.tecnico_dataNascita" placeholder="GG/MM/AAAA" maxlength="10" mask="d0/M0/0000" required />
                        </clr-date-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Codice fiscale</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_cf" name="tecnico_cf" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Partita IVA</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_piva" name="tecnico_piva" required/>
                        </clr-input-container>
                    </div>
                </div>
                <clr-input-container>
                    <label class="clr-required-mark">Indirizzo email</label>
                    <input clrInput type="text" [(ngModel)]="model.email" name="email" required/>
                </clr-input-container>
                <clr-input-container>
                    <label>Inviare mail in copia conoscenza</label>
                    <input clrInput type="email" [(ngModel)]="model.emailCC" name="emailCC"/>
                </clr-input-container>
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Codice univoco</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_codiceUnivoco" name="tecnico_codiceUnivoco" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">PEC</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_pec" name="tecnico_pec" required/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-select-container>
                            <label class="clr-required-mark">Iscritto all'albo</label>
                            <select clrSelect name="options" [(ngModel)]="model.tecnico_albo">
                                <option [value]="albo.GEOMETRA">Geometra</option>
                                <option [value]="albo.ARCHITETTO">Architetto</option>
                                <option [value]="albo.INGEGNERE">Ingegnere</option>
                                <option [value]="albo.PERITO_EDILE">Perito edile</option>
                                </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Provincia albo</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_provincia_albo" name="tecnico_provincia_albo" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Numero iscrizione albo</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_numero_iscrizione_albo" name="tecnico_numero_iscrizione_albo" required/>
                        </clr-input-container>
                    </div>
                </div>
                

                <h3 class="mt-3">Dati sede</h3>
                <clr-input-container>
                    <label class="clr-required-mark">Indirizzo</label>
                    <input clrInput type="text" [(ngModel)]="model.tecnico_sede_indirizzo" name="tecnico_sede_indirizzo" required/>
                </clr-input-container>
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">CAP</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_sede_cap" name="tecnico_sede_cap" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Comune</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_sede_localita" name="tecnico_sede_comune" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Provincia</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_sede_provincia" name="tecnico_sede_provincia" required/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Telefono</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_sede_telefono" name="tecnico_sede_telefono" required/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label class="clr-required-mark">Cellulare</label>
                            <input clrInput type="text" [(ngModel)]="model.tecnico_sede_cellulare" name="tecnico_sede_cellulare" required/>
                        </clr-input-container>
                    </div>
                </div>

                <h3 class="mt-3">Dati accesso al portale</h3>
            </ng-container>
            <clr-input-container *ngIf="ruolo == 'interno'">
                <label class="clr-required-mark">Indirizzo email</label>
                <input clrInput type="text" [(ngModel)]="model.email" name="email" required/>
            </clr-input-container>
            <clr-password-container>
                <label>Password <span *ngIf="edit">(lascia vuota per non modificare)</span></label>
                <input clrPassword placeholder="" [(ngModel)]="model.password" name="password"/>
            </clr-password-container>
            <clr-password-container>
                <label>Conferma password</label>
                <input clrPassword placeholder="" [(ngModel)]="confermaPassword" name="confermaPassword"/>
            </clr-password-container>
            <ng-container *ngIf="auth.isAdmin">
                <h3 class="mt-3" *ngIf="ruolo != 'interno'">Agenzie a cui è associato:</h3>
                <ul>
                    <li *ngFor="let soc of model.societa"><a routerLink="/anagrafica-societa/{{soc.id}}">{{soc.ragioneSociale}}</a></li>
                </ul>
            </ng-container>
            <ng-container *ngIf="auth.isAdmin">
                <h3 class="mt-3">Altri dati</h3>

                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="accettatoDaStudio" name="accettatoDaStudio" [(ngModel)]="model.accettatoDaStudio"/>
                            <label for="accettatoDaStudio">Utente confermato da studio</label>
                        </div>
                    </div>
                </div>

                <p *ngIf="edit"><strong>Data accettazione condizioni</strong>: {{ model.dataAccettazioneCondizioni != "01/01/0001" ? model.dataAccettazioneCondizioni : "Non ancora accettate" }}</p>

                <clr-textarea-container>
                    <label>Nota amministratore</label>
                    <textarea clrTextarea [(ngModel)]="model.notaAmministratore" name="notaAmministratore"></textarea>
                </clr-textarea-container>
            </ng-container>
            <div class="clr-form-control" *ngIf="edit">
                <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                        <input type="checkbox" clrCheckbox id="inactive" name="inactive" [ngModel]="!model.active" (ngModelChange)="model.active = !$event"/>
                        <label for="inactive">Disabilitato</label>
                    </div>
                </div>
            </div> 
            <button class="btn btn-primary btn-block mt-2" type="submit">Salva</button>
        </form>
    </div>
</div>