<div class="page-container input-block p-2">
    <form clrForm ngNativeValidate clrLayout="vertical" (submit)="save()">
        <div class="logo"><img [src]="logoSrc" onerror="this.style.display='none'" onload="this.style.display='block'" /></div>
        <div class="text-center">
            <button type="button" class="btn btn-secondary" (click)="logoUploader.click()">Carica logo agenzia</button>
        </div>
        <h3>Anagrafica agenzia</h3>
        <div class="clr-row">
            <div class="clr-col">
                <clr-input-container>
                    <label class="clr-required-mark">Ragione sociale</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.ragioneSociale" name="ragioneSociale" required/>
                </clr-input-container>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col">
                <clr-input-container>
                    <label class="clr-required-mark">Indirizzo</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.indirizzo" name="indirizzo" required/>
                </clr-input-container>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col-md">
                <clr-input-container>
                    <label class="clr-required-mark">CAP</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.cap" name="cap" required/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label class="clr-required-mark">Comune</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.citta" name="citta" required/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label class="clr-required-mark">Provincia</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.provincia" name="provincia" required/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label class="clr-required-mark">Regione</label>
                    <input clrInput type="text" [(ngModel)]="model.societa.regione" name="regione" required/>
                </clr-input-container>
            </div>
        </div>

        <h3 class="mt-3">Contatti</h3>
        <clr-input-container>
            <label class="clr-required-mark">Indirizzo email</label>
            <input clrInput type="text" [(ngModel)]="model.societa.email" name="email" required/>
        </clr-input-container>
        <clr-input-container>
            <label>Inviare mail in copia conoscenza
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Le mail indirizzate a questa agenzia, verranno inviate in copia conoscenza agli indirizzi inseriti nel campo qui sotto. Puoi inserire più indirizzi email separati da una virgola</span>
                </a>
            </label>
            <input clrInput type="text" [(ngModel)]="model.societa.emailCC" name="emailCC"/>
        </clr-input-container>
        <clr-input-container>
            <label class="clr-required-mark">Telefono</label>
            <input clrInput type="text" [(ngModel)]="model.societa.telefono" name="telefono" required/>
        </clr-input-container>
        <clr-input-container>
            <label class="clr-required-mark">Referente</label>
            <input clrInput type="text" [(ngModel)]="model.societa.referente" name="referente" required/>
        </clr-input-container>

        <h3 class="mt-3">Dati fiscali e operativi</h3>
        <clr-input-container>
            <label class="clr-required-mark">Codice fiscale</label>
            <input clrInput type="text" [(ngModel)]="model.societa.cf" name="cf" required/>
        </clr-input-container>
        <clr-input-container>
            <label class="clr-required-mark">Partita IVA</label>
            <input clrInput type="text" [(ngModel)]="model.societa.piva" name="piva" required/>
        </clr-input-container>
        <clr-input-container>
            <label class="clr-required-mark">PEC</label>
            <input clrInput type="text" [(ngModel)]="model.societa.pec" name="pec" required/>
        </clr-input-container>
        <clr-input-container>
            <label class="clr-required-mark">Codice univoco</label>
            <input clrInput type="text" [(ngModel)]="model.societa.sdi" name="sdi" required/>
        </clr-input-container>
        <clr-input-container *ngIf="auth.isAdmin">
            <label class="clr-required-mark">Importo quota</label>
            <input clrInput type="text" [(ngModel)]="model.societa.importoQuota" name="importoQuota" required/>
        </clr-input-container>
        <clr-date-container *ngIf="auth.isAdmin">
            <label class="clr-required-mark">Data scadenza quota</label>
            <input type="date" autocomplete="off" clrDate name="dataScadenzaQuota" [(ngModel)]="model.societa.dataScadenzaQuota" placeholder="GG/MM/AAAA" maxlength="10" mask="d0/M0/0000" required />
        </clr-date-container>
        <ng-container *ngIf="auth.isAdmin">
            <h3 class="mt-3">Altri dati</h3>

            <div class="clr-form-control">
                <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                        <input type="checkbox" clrCheckbox id="accettatoDaStudio" name="accettatoDaStudio" [(ngModel)]="model.societa.accettatoDaStudio"/>
                        <label for="accettatoDaStudio">Agenzia confermata da studio</label>
                    </div>
                </div>
            </div>

            <clr-textarea-container>
                <label>Nota amministratore</label>
                <textarea clrTextarea [(ngModel)]="model.societa.notaAmministratore" name="notaAmministratore"></textarea>
            </clr-textarea-container>
        </ng-container>
        
        <button class="btn btn-primary btn-block mt-2" type="submit" [disabled]="loading">SALVA</button>
        <input type="file" style="display: none" (change)="changeFileLogo($event)" accept="image/png" #logoUploader/>
    </form>
</div>
<div class="page-container input-block p-2">
    <h2>{{ (auth.isAdmin || auth.isTeamLeader) ? "Tecnici dell'agenzia" : "I tuoi tecnici" }}</h2>
    <div class="tecnici">
        <p *ngIf="model.listaTecnici.length == 0">Non hai ancora nessun tecnico assegnato</p>
        <div class="tecnico" *ngFor="let tec of model.listaTecnici">
            <p>
                {{tec.nome}} - <a (click)="displayModalInfoTecnico(tec)" href="javascript://" style="color: blue;">Visualizza Dati</a><ng-container *ngIf="!tec.tecnico_interno || auth.isAdmin"> - <a (click)="displayModalRimuoviTecnico(tec.id)" href="javascript://" style="color: red;">Rimuovi Tecnico</a></ng-container>
            </p>
        </div>
    </div>
    <button type="button" class="btn btn-primary mt-2" (click)="showModalInvitoTecnico = true" *ngIf="!model.societa.interna || (model.societa.interna && model.societa.ibrida)">Invita tecnico</button>
    <ng-container *ngIf="auth.isAdmin">
        <p class="mt-3">Oppure selezionalo dalla lista qui sotto:</p>
        <div class="clr-row">
            <div class="clr-col-md-3">
                <clr-toggle-container>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [(ngModel)]="mostraTecniciInterni" />
                      <label>Tecnici interni</label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>
            </div>
            <div class="clr-col-md-9">
                <clr-select-container>
                    <select clrSelect [(ngModel)]="modelAssegnaTecnico.tecnicoId" name="modelAssegnaTecnicotecnicoId">
                        <ng-container *ngFor="let tec of model.listaTecniciDisponibili">
                            <option *ngIf="(tec.tecnico_interno || tec.tecnico_esterno_spa) == mostraTecniciInterni" [value]="tec.id">{{tec.nome}}</option>
                        </ng-container>
                    </select>
                </clr-select-container>
            </div>
        </div>
        <div class="clr-form-control">
            <div class="clr-control-container">
                <clr-radio-container>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="assegnaTecnicoAssegnaPratichePrecedenti" [value]="false" [(ngModel)]="modelAssegnaTecnico.assegnaPratichePrecedenti" />
                        <label>Il nuovo tecnico vedrà solo le pratiche nuove a lui assegnate</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="assegnaTecnicoAssegnaPratichePrecedenti" [value]="true" [(ngModel)]="modelAssegnaTecnico.assegnaPratichePrecedenti" />
                        <label>Assegna tutte le pratiche precedenti a questo nuovo tecnico</label>
                    </clr-radio-wrapper>
                </clr-radio-container>
            </div>
        </div>
        <button (click)="assegnaTecnico()" class="btn btn-primary mt-2">Assegna tecnico</button>
    </ng-container>
</div>

<div class="page-container input-block p-2" *ngIf="auth.isAdmin || auth.isTeamLeader">
    <h2>Utenti dell'agenzia</h2>
    <div><button (click)="nuovoUtente()" class="btn btn-primary"><fa-icon [icon]="faPlus"></fa-icon> Nuovo utente</button></div>
    <h3 class="text-center">Abilitati</h3>
    <div class="table-container">
        <table class="table">
            <thead>
                <th>Nome e cognome</th>
                <th>Email</th>
                <th>Ruolo</th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of model.listaUtenti">
                    <tr *ngIf="user.active">
                        <td>{{user.nome}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.ruolo}}</td>
                        <td><button class="btn btn-primary btn-icon" (click)="editUser(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <ng-container *ngIf="showDisabilitati">
        <h3 class="text-center">Disabilitati</h3>
        <div class="table-container">
            <table class="table">
                <thead>
                    <th>Nome e cognome</th>
                    <th>Email</th>
                    <th>Ruolo</th>
                    <th></th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let user of model.listaUtenti">
                        <tr *ngIf="!user.active">
                            <td>{{user.nome}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.ruolo}}</td>
                            <td><button class="btn btn-primary btn-icon" (click)="editUser(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>

<clr-modal [(clrModalOpen)]="showModalInvitoTecnico">
    <h3 class="modal-title text-center">Inserisci i dati del tecnico che vuoi invitare</h3>
    <div class="modal-body">
        <form clrForm ngNativeValidate clrLayout="vertical" (submit)="invitaTecnico()">
            <div class="input-block">
                <clr-input-container>
                    <label class="clr-required-mark">Nome</label>
                    <input clrInput type="text" [(ngModel)]="modelInvitoTecnico.nome" name="nome" required/>
                </clr-input-container>
                <clr-input-container>
                    <label class="clr-required-mark">Cognome</label>
                    <input clrInput type="text" [(ngModel)]="modelInvitoTecnico.cognome" name="cognome" required/>
                </clr-input-container>
                <clr-input-container>
                    <label class="clr-required-mark">Email</label>
                    <input clrInput type="email" [(ngModel)]="modelInvitoTecnico.email" name="email" required/>
                </clr-input-container>
                <!-- <div class="clr-form-control">
                    <div class="clr-control-container">
                        <clr-radio-container>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="assegnaTecnicoAssegnaPratichePrecedenti" [value]="false" [(ngModel)]="modelInvitoTecnico.assegnaPratichePrecedenti" />
                                <label>Il nuovo tecnico vedrà solo le pratiche nuove a lui assegnate</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="assegnaTecnicoAssegnaPratichePrecedenti" [value]="true" [(ngModel)]="modelInvitoTecnico.assegnaPratichePrecedenti" />
                                <label>Assegna tutte le pratiche precedenti a questo nuovo tecnico</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                </div> -->
                <div class="clr-row invito-tecnico-btn-row">
                    <div class="clr-col">
                        <p class="text-center">Il nuovo tecnico vedrà solo le pratiche nuove a lui assegnate</p>
                        <div>
                            <button type="submit" class="btn btn-primary btn-block">INVITA</button>
                        </div>
                    </div>
                    <div class="clr-col-1 btn-spacer"><div class="spacer"></div></div>
                    <div class="clr-col">
                        <p class="text-center">Assegna tutte le pratiche precedenti a questo nuovo tecnico</p>
                        <div>
                            <button type="submit" class="btn btn-primary btn-block">INVITA</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </form>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalRimuoviTecnico">
    <h3 class="modal-title text-center">Confermi di voler rimuovere il tecnico?</h3>
    <div class="modal-body">
        <div class="input-block">
            <p>Se vuoi riassegnare tutte le pratiche del tecnico che stai eliminando, seleziona il nuovo tecnico a cui saranno assegnate. Altrimenti lascia il campo vuoto.</p>
            <clr-select-container>
                <label>Tecnico per riassegnazione pratiche</label>
                <select clrSelect [(ngModel)]="nuovoTecnicoRiassegnazione" name="nuovoTecnicoRiassegnazione">
                    <option value="">-- Non riassegnare le pratiche a nessun tecnico</option>
                    <ng-container *ngFor="let tec of model.listaTecnici">
                        <option *ngIf="tec.id != tecnicoRimozioneId" [value]="tec.id">{{tec.nome}}</option>
                    </ng-container>
                </select>
            </clr-select-container>
            <div class="mt-2">
                <button type="button" (click)="rimuoviTecnico()" class="btn btn-primary btn-block">Rimuovi tecnico</button>
            </div>
        </div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalInfoTecnico">
    <h3 class="modal-title text-center">Informazioni tecnico</h3>
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col">
                <p>
                    <strong>Nome e cognome</strong><br/>
                    {{infoTecnico.nome}}
                </p>
            </div>
            <div class="clr-col">
                <p>
                    <strong>Email</strong><br/>
                    {{infoTecnico.email}}
                </p>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col" *ngIf="infoTecnico.tecnico_cf.length > 0">
                <p>
                    <strong>CF</strong><br/>
                    {{infoTecnico.tecnico_cf}}
                </p>
            </div>
            <div class="clr-col" *ngIf="infoTecnico.tecnico_sede_indirizzo.length > 0">
                <p>
                    <strong>Sede</strong><br/>
                    {{infoTecnico.tecnico_sede_indirizzo}} {{infoTecnico.tecnico_sede_localita.length > 0 ? '- ' + infoTecnico.tecnico_sede_localita : ''}} {{infoTecnico.tecnico_sede_provincia.length > 0 ? '(' + infoTecnico.tecnico_sede_provincia + ')' : ''}}
                </p>
            </div>
        </div>
        <div class="clr-row" *ngIf="infoTecnico.tecnico_sede_telefono.length > 0 || infoTecnico.tecnico_sede_cellulare.length > 0">
            <div class="clr-col">
                <p>
                    <strong>Telefono</strong><br/>
                    {{infoTecnico.tecnico_sede_telefono}} {{infoTecnico.tecnico_sede_cellulare.length > 0 ? '/ ' + infoTecnico.tecnico_sede_cellulare : ''}}
                </p>
            </div>
        </div>
    </div>
</clr-modal>