import { RTI_TipoProtocolloData } from "../const/pratica-const";
import { Documento } from "./documento";

export class RTIModel {
    id: number = 0;
    praticaId: number = 0;
    indirizzo_comune: string = "";
    indirizzo_viaPiazza: string = "";
    indirizzo_piano: string = "";
    indirizzo_scala: string = "";
    indirizzo_interno: string = "";
    riferimenti_foglio: string = "";
    riferimenti_particella: string = "";
    riferimenti_subalterno: string = "";
    riferimenti_categoria: string = "";
    riferimenti_classe: string = "";
    riferimenti_vani: string = "";
    riferimenti_rendita: string = "";
    certificatore_nomeCognome: string = "";
    certificatore_cittaNascita: string = "";
    certificatore_dataNascita: string = "";
    certificatore_indirizzoVia: string = "";
    certificatore_telefono: string = "";
    certificatore_titolo: string = "";
    certificatore_cf: string = "";
    certificatore_indirizzoCitta: string = "";
    certificatore_mail: string = "";
    certificatore_ordine: string = "";
    sopralluogo_effettuato: boolean = false;
    sopralluogo_data: string = "";
    sopralluogo_note: string = "";
    ante1942: boolean = false;
    noAgibilita: boolean = false;
    conformitaCatastale_check1: boolean = false;
    conformitaCatastale_check2: boolean = false;
    conformitaCatastale_check3: boolean = false;
    conformitaEdilizia_check1: boolean = false;
    conformitaEdilizia_check2: boolean = false;
    attestatoPrestazioneEnergetica: boolean = false;
    noteOsservazioni: string = "";
    documenti: Documento[] = [];
    protocolliData: RTIProtocolloData[] = [];
    
    // NO DB
    protocolliDataDoubleArray: RTIProtocolloData[][] = [];
    documentiPlanimetrie: Documento[] = [];

}

export class RTIProtocolloData {
    id: number = 0;
    tipo: RTI_TipoProtocolloData = RTI_TipoProtocolloData.AGIBILITA_DOMANDA_RILASCIO_CERTIFICATO;
    note: string = "";
    protocollo: string = "";
    data: string = "";
}